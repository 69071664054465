import get from 'lodash/get';

export default {
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allValues() {
      return this.options.map(this.filterValue);
    },
  },
  methods: {
    filterValue(item) {
      return get(item, 'value');
    },
  },
};
