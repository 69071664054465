import { render, staticRenderFns } from "./CheckboxItemGroup.vue?vue&type=template&id=03be150c&scoped=true"
import script from "./CheckboxItemGroup.vue?vue&type=script&lang=js"
export * from "./CheckboxItemGroup.vue?vue&type=script&lang=js"
import style0 from "./CheckboxItemGroup.vue?vue&type=style&index=0&id=03be150c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03be150c",
  null
  
)

export default component.exports