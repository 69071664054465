<template>
<v-card>

  <v-container fluid class="mb-4">
    <v-row>
      <v-col
        v-for="({ text, value, disabled }, i) in options"
        :key="i"
        :cols="cols">

        <v-checkbox
          v-model="selected"
          :true-value="value"
          :value="value"
          :label="text"
          :disabled="disabled"
          multiple
          dense
          hide-details
          class="mt-2"
          color="primary"
        ></v-checkbox>

      </v-col>
    </v-row>
  </v-container>

  <v-card-actions class="flex-wrap pb-0">

    <v-btn
      v-if="selectAll"
      :disabled="selected.length == allValues.length - disabledOptions.length"
      @click="selectAllAction"
      text color="primary"
      class="mr-2 mb-2"
      outlined
      >{{ 'Select All' }}</v-btn>

    <v-btn
      v-for="({text, items}, i) in actionGroups"
      :key="i"
      text color="primary"
      :disabled="disableAction(items)"
      @click="setSelected(items)"
      class="ml-0 mr-2 mb-2"
      outlined
      >{{ text }}</v-btn>

  </v-card-actions>

  <v-card-actions class="pt-0">
    <v-spacer></v-spacer>

    <v-btn
      v-if="clearable"
      :disabled="selected.length == []"
      @click="selected = []"
      text color="primary">{{ 'Clear' }}</v-btn>

    <slot name="actions"></slot>
  </v-card-actions>
</v-card>
</template>

<script>
import difference from 'lodash/difference';
import { concatAndDeDuplicate } from '../../../utils/helpers';
import Selectable from '../../mixins/Selectable';
import Clearable from '../../mixins/Clearable';

export default {
  name: 'CheckboxGridPicker',
  mixins: [Selectable, Clearable],
  props: {
    cols: {
      type: Number,
      default: 3,
    },
    actionGroups: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    disabledOptions() {
      return this.options.filter(item => item.disabled).map(item => item.value);
    },
  },
  methods: {
    setSelected(val) {
      this.selected = difference(concatAndDeDuplicate(this.selected, val), this.disabledOptions);
    },
    selectAllAction() {
      this.selected = difference(this.allValues, this.disabledOptions);
    },
    disableAction(items) {
      return difference(items, this.disabledOptions).every(item => this.selected.includes(item));
    },
  },
};
</script>
