export default {
  props: {
    selectedItems: {
      type: [Array, String, Number],
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: ['selectedItems'],
    event: 'input',
  },
  computed: {
    selected: {
      get() {
        return this.selectedItems || [];
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
