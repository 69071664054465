<script>
import { dropdownIcon } from '@/utils/helpers';
import Selectable from '@/components/mixins/Selectable';
import Clearable from '@/components/mixins/Clearable';
import TabbedGridPicker from '@/components/ui/pickers/TabbedGridPicker.vue';
import Messages from '@/enums/Messages';
import TextFormatter from '@/formatters/TextFormatter';

export default {
  name: 'TabbedGridDropdown',
  components: {
    TabbedGridPicker,
  },
  mixins: [Selectable, Clearable],
  props: {
    label: {
      type: String,
      default: '',
    },

    allText: {
      type: String,
      default: Messages.ALL,
    },

    minWidth: {
      type: Number,
      default: 219,
    },

    maxWidth: {
      type: Number,
      default: 600,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    groupAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      picker: false,
      closeOnClick: true,
      closeOnContentClick: false,
      messages: Messages,

      tab: null,
    };
  },
  computed: {
    textFieldValue() {
      if (this.selected.length && this.selected.length !== this.allValues.length) {
        return TextFormatter.selectedMultipleItems(this.selected.length);
      }
      return this.allText;
    },

    appendIcon() {
      return dropdownIcon(this.picker);
    },
  },
};
</script>

<template>
  <v-menu
    ref="picker"
    v-model="picker"
    :close-on-click="closeOnClick"
    :close-on-content-click="closeOnContentClick"
    transition="scale-transition"
    :min-width="minWidth"
    :max-width="maxWidth"
    offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="textFieldValue"
        :label="label"
        dense
        hide-details
        outlined
        readonly
        v-on="on"
        :append-icon="appendIcon"
        @click:append="picker = !picker"
        v-bind="attrs"
      ></v-text-field>
    </template>
    <tabbed-grid-picker
      v-model="selected"
      :options="options"
      :clearable="clearable"
      :select-all="selectAll"
      :cols="3"
      :tab.sync="tab"
      :multiple="multiple"
      :group-all="groupAll"
      class="pa-2 px-3"
    >
      <template v-slot:actions>
        <v-btn text color="primary" @click="picker = false">{{ messages.CLOSE }}</v-btn>
      </template>
    </tabbed-grid-picker>
  </v-menu>
</template>
