import { mapOptionsToSelect } from '@/utils/mappers';
import ResultFilter from '@/filters/ResultFilter';
import Selectable from '@/components/mixins/Selectable';
import FilterableSelector from '@/components/mixins/FilterableSelector';
import TabbedGridDropdown from '@/components/ui/dropdowns/TabbedGridDropdown.vue';

export default {
  name: 'PlayOutcomeDropdown',

  mixins: [Selectable, FilterableSelector],

  props: {
    label: {
      type: String,
      default: 'Play Outcome',
    },

    hasExtendedTagging: {
      type: Boolean,
      default: false
    },

    options: {
      type: Array,
      default: () => [],
    },

    multiple: {
      type: Boolean,
      default: true,
    },

    groupAll: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    TabbedGridDropdown,
  },

  data() {
    return {
      picker: false,
    }
  },
  computed: {
    _options() {
      if (this.options.length) return this.options;
      return mapOptionsToSelect(ResultFilter.PlayOutcome.key, {hasExtendedTagging: this.hasExtendedTagging});
    },
  }
};
