<script>
import Selectable from '../../mixins/Selectable';
import CheckboxItemGroup from '../selection-controls/CheckboxItemGroup.vue';

export default {
  name: 'CheckboxGroupPicker',
  components: {
    CheckboxItemGroup,
  },
  mixins: [Selectable],
  data() {
    return {
      multiple: true,
    };
  },
};
</script>

<template>
  <v-card tile>
    <v-container>
    <v-row class="my-0 py-0">
      <v-col
        cols="12"
        v-for="({title, items, color}, i) in options"
        :key="i"
        class="my-0 py-0"
      >
        <checkbox-item-group
          :title="title"
          :options="items"
          :multiple="multiple"
          :color="color?color:''"
          v-model="selected"
        ></checkbox-item-group>
      </v-col>
    </v-row>
    </v-container>

    <slot></slot>

    <v-card-actions>
    <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>
