<script>
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import Selectable from '../../mixins/Selectable';

export default {
  name: 'CheckboxItemGroup',
  mixins: [Selectable],
  props: {
    title: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columnMargins: 'my-0 py-0',
    };
  },
  computed: {
    disabledValues() {
      return this.options.filter(item => item.disabled).map(item => item.value );
    },
    itemsValues() {
      return this.options.map(item => item.value);
    },
    hasAllItems() {
      const tmp = difference(this.itemsValues, this.disabledValues);
      if (tmp.length) {
        return tmp.every(this.inSelected);
      }
      return false;
    },
    hasSomeItems() {
      return this.itemsValues.some(this.inSelected);
    },
    allDisabled() {
      return this.itemsValues.length == this.disabledValues.length;
    },
    allCheck() {
      return this.hasAllItems && !this.allDisabled;
    },
    indeterminate() {
      return !this.hasAllItems && this.hasSomeItems;
    },
  },
  methods: {
    inSelected(element) {
      return this.selected.includes(element);
    },
    toggle() {
      // this.$nextTick(() => {
      let diff1 = difference(uniq([...this.selected, ...this.itemsValues]), this.disabledValues);
      let diff2 = difference(this.selected, this.itemsValues, this.disabledValues);
      if (this.allCheck) {
        this.selected = diff2;
      } else {
        this.selected = diff1;
      }
      // });
    },
  },
};
</script>

<template>
  <v-container fluid :class="columnMargins">
    <v-row :class="columnMargins">
      <v-col cols="12" v-if="title" :class="columnMargins">
        <v-subheader
          :class="color?`${color}--text ${columnMargins}`:columnMargins"
        >{{ title }}</v-subheader>
      </v-col>

      <v-col v-if="all" cols=2 :class="columnMargins">
        <v-checkbox
          :indeterminate="indeterminate"
          :value="allCheck"
          :disabled="allDisabled"
          label="All"
          color="red"
          hide-details
          dense
          @click.stop="toggle"
          class="denseBox"
        ></v-checkbox>
      </v-col>

      <v-col :cols="all?10:12" :class="columnMargins">
        <v-row :class="columnMargins">
          <v-col
            v-for="({ value, text, disabled }) in options"
            :key="value"
            :class="columnMargins"
            cols="4"
          >
            <v-checkbox
              :label="text"
              :true-value="value"
              :multiple="multiple"
              :value="value"
              :disabled="allDisabled || disabled"
              :class="['inputLabelAutoHeight', 'denseBox']"
              v-model="selected"
              color="red"
              dense
              hide-details
            ></v-checkbox>
          </v-col>

        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.denseBox {
  margin-top: 0 !important;
}

.inputLabelAutoHeight {
  color: red !important;

  &::v-deep {
    .v-label {
      height: auto;
    }
  }
}
</style>
