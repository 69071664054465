const baseballFoulLineSI = {
  name: 'foulLine',
  type: 'line',
  data: [
    { x: -65, y: 65 },
    { x: 0, y: 0 },
    { x: 65, y: 65 },
    { x: 19.4, y: 19.4 },
    { x: 0, y: 38.8 },
    { x: -19.4, y: 19.4 },
  ],
};

/**
 * Get coordinates of point on cirle wiht specific radius, starting point and angle
 * @param {Object} startingPoint Cartesian point { X0, y0 }
 * @param {Number} r Radius
 * @param {Number} t Angle
 */
function getCoords({ x0, y0 }, r, t) {
  const x = r * Math.cos(t) + x0;
  const y = r * Math.sin(t) + y0;

  return { x, y };
}

function getGrassLine({ x0, y0 } = { x0: 0, y0: 18.39 }, radius = 28.9) {
  let grassLine = { name: 'grassLine', type: 'line', data: [] };

  for (let i = 0.33; i < 2.83; i += 0.02) {
    grassLine.data.push(getCoords({ x0, y0 }, radius, i));
  }
  grassLine.data.push(getCoords({ x0, y0 }, radius, 2.83));

  return grassLine;
}

const baseballGrassLineTraceSI = getGrassLine();

const ChartTypeEnum = Object.freeze({
  SPRAY_CHART: {
    type: 'spray-chart',
    storeKey: 'hit_spray',
  },

  SPRAY_CHART_PITCHING: {
    type: 'spray-chart-pitching',
    storeKey: 'pitch_spray',
  },

  STRIKE_ZONE: {
    type: 'strike-zone',
    storeKey: 'strikezone_chart',
  },

  BATTER_PERFORMANCE: {
    type: 'batter-performance',
    storeKey: 'hit_tracking',
  },

  BATTED_BALL_QUALITY: {
    type: 'batted-ball-quality',
    storeKey: 'batted_ball_quality',
  },

  CONTACT_REPORT: {
    type: 'contact-report',
    storeKey: 'contact_report',
  },

  CONTACT_REPORT_PITCHING: {
    type: 'contact-report-pitching',
    storeKey: 'contact_report_pitching',
  },

  PITCHER_PERFORMANCE: {
    type: 'pitcher-performance',
    storeKey: 'pitch_tracking',
  },

  RELEASE_POINT: {
    type: 'release-point',
    storeKey: 'release_point',
  },

  PITCH_LOCATION: {
    type: 'pitch-location',
    storeKey: 'location_chart',
  },

  RELEASE_EXTENSION: {
    type: 'release-extension',
    storeKey: 'release_extension',
  },

  PITCH_MOVEMENT: {
    type: 'pitch-movement',
    storeKey: 'pitch_movement',
  },

  CONTACT_POINT: {
    type: 'contact-point',
    storeKey: 'contact_point',
  },

  DEFAULT: {
    type: 'default',
    storeKey: 'none',
  },
});

export { baseballFoulLineSI, baseballGrassLineTraceSI, getCoords, ChartTypeEnum };
