<script>
import flatten from 'lodash/flatten';
import difference from 'lodash/difference';
import { dropdownIcon } from '@/utils/helpers';
import Selectable from '../../mixins/Selectable';
import CheckboxGroupPicker from '../pickers/CheckboxGroupPicker.vue';
import Messages from '../../../enums/Messages';
import TextFormatter from '../../../formatters/TextFormatter';

export default {
  name: 'CheckboxGroupDropdown',
  components: {
    CheckboxGroupPicker,
  },
  mixins: [Selectable],
  props: {
    label: {
      type: String,
      default: '',
    },
    allText: {
      type: String,
      default: Messages.ALL,
    },
    possibleOptions: {
      type: Array,
      required: true,
    },
    minWidth: {
      type: Number,
      default: 219,
    },
    maxWidth: {
      type: Number,
      default: 490,
    },
  },
  data() {
    return {
      picker: false,
      closeOnClick: true,
      closeOnContentClick: false,
      messages: Messages,
    };
  },
  computed: {
    allValues() {
      return flatten(this.options.map(({ items }) => items.map(({ value }) => value)));
    },
    textFieldValue() {
      if (this.selected.length && this.selected.length !== this.allValues.length) {
        return TextFormatter.selectedMultipleItems(this.selected.length);
      }
      return this.allText;
    },
    filteredOptions() {
      return this.options.map((option) => {
        let items = option.items.map((item) => {
          if (!this.possibleOptions.includes(item.value)) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
        return option;
      });
    },

    appendIcon() {
      return dropdownIcon(this.picker);
    }
  },
  methods: {
    setAllValues() {
      this.selected = this.possibleOptions;
    },
  },
};
</script>

<template>
  <v-menu
    v-model="picker"
    :close-on-click="closeOnClick"
    :close-on-content-click="closeOnContentClick"
    transition="scale-transition"
    :min-width="minWidth"
    :max-width="maxWidth"
    offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="textFieldValue"
        :label="label"
        dense
        hide-details
        outlined
        readonly
        v-on="on"
        :append-icon="appendIcon"
        @click:append="picker = !picker"
        v-bind="attrs"
      ></v-text-field>
    </template>
    <checkbox-group-picker v-model="selected" :options="filteredOptions" scrollable>
      <template v-slot:actions>
        <v-btn text color="primary" @click="setAllValues()">{{ messages.SELECT_ALL }}</v-btn>
        <v-btn text color="primary" @click="selected = []">{{ messages.CLEAR_ALL }}</v-btn>
        <v-btn text color="primary" @click="picker = false">{{ messages.CLOSE }}</v-btn>
      </template>
    </checkbox-group-picker>
  </v-menu>
</template>
