export default {
  props: {
    selectedItems: {
      type: [Array, String, Number],
      default: () => [],
    },

    options: {
      type: Array,
    },

    itemsPolicy: {
      type: String,
      // should be either whitelist or blacklist
      default: 'blacklist',
    },

    list: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filteredOptions() {
      if (this.options && Array.isArray(this.options)) {
        return this.options.map(this.mapper);
      }
      return [];
    },
  },

  methods: {
    mapper(item) {
      return item;
    },
  },
};
