import { Converters, Enums, Providers, ValueObjects } from '@flightscope/baseball-stats';
import { StrikeZoneDataBinning } from '@flightscope/baseball-stats/src/classificators/dataBinning';

const POV = Object.freeze({
  BATTER: 1,
  PITCHER: 2,
});

const SVG = Object.freeze({
  PATH: {
    LINE: 'L',
    MOVE: 'M',
  },
});

const ZONES = Object.freeze({
  OUTER: {
    TOP: {
      LEFT: 'OTL',
      RIGHT: 'OTR'
    },
    BOTTOM: {
      LEFT: 'OBL',
      RIGHT: 'OBR'
    }
  }
});

const magnify = (val) => val * 200;

function scalePoints(point) {
  if (!Array.isArray(point)) return null;
  if (!point.length) return null;

  return point.map(magnify);
}

function pointToSvgPoint(point, index, orig) {
  if (!Array.isArray(point)) return null;
  if (!point.length) return null;

  let action = SVG.PATH.LINE;

  if (index === 0) {
    action = SVG.PATH.MOVE;
  }

  return `${action} ${point.toString()}`;
}

function toZone([sector, { value, color }, points, zone]) {
  if (!Array.isArray(points)) return null;
  if (!points.length) return null;
  if (value === undefined) return null;

  let textProps = {};
  let groupProps = {};

  if (sector) {
    groupProps.class = `zone-${sector}`;
  }

  let scaledPoints = points.map(scalePoints).filter((point) => point);
  let svgPoints = scaledPoints.map(pointToSvgPoint);
  let d = svgPoints.join(' ');

  // if (value !== undefined) {
  let p;
  let fontSize = 14;
  let textPadding = {
    x: 5,
    y: 5,
  };
  let textOffsetX = 0;
  let textOffsetY = 0;

  textProps['font-size'] = fontSize;

  switch (zone) {
    case ZONES.OUTER.TOP.RIGHT:
      [, p] = scaledPoints;
      textOffsetX = -value.toString().length * fontSize + textPadding.x;
      textOffsetY = textPadding.y;
      break;

    case ZONES.OUTER.BOTTOM.LEFT:
      [, , , , , p] = scaledPoints;
      textOffsetX = textPadding.x;
      textOffsetY = -fontSize - textPadding.y;
      break;

    case ZONES.OUTER.BOTTOM.RIGHT:
      [, , p] = scaledPoints;
      textOffsetX = -value.toString().length * fontSize + textPadding.x;
      textOffsetY = -fontSize - textPadding.y;
      break;

    case ZONES.OUTER.TOP.LEFT:
    default:
      [p] = scaledPoints;
      textOffsetX = textPadding.x;
      textOffsetY = textPadding.y;
  }
  let [x, y] = p;
  textProps.x = x + textOffsetX;
  textProps.y = -y + fontSize + textOffsetY;
  // }

  return {
    textProps,
    pathProps: {
      d,
      fill: color.toHex(),
      fillOpacity: color.alpha / 255,
    },
    text: value,
    groupProps,
  };
}

function colorSteps(series) {
  if (!series.length) {
    return false;
  }

  const colorConverter = new Converters.RangeToColorConverters.LinearRangeToColorConverter(
    new ValueObjects.RGBA(255, 0, 0, 10),
    new ValueObjects.RGBA(255, 0, 0, 255),
  );
  const colorsPerSector = Providers.HeatMapColorsPerSectorProvider(series, colorConverter, Enums.SeriesDimension.Y);

  return colorsPerSector;
}

function strikeZonePropsProvider(
  chartContext,
  data,
  {
    // axisSettings = {},
    // axisRanges = {},
    // convertDimensions,
    conf,
    // axisUnit = []
  },
  // { unitSystem },
) {
  if ( conf ) {
    let { title, description, playerName } = conf;
    title = playerName ? `${title} for ${playerName}` : title;
    return { title, description, value: data };
  }
  return { value: data };
}

function strikeZonePropsGuard(props) {
  return props && props.hasOwnProperty('value') && props.value;
}

function strikeZoneSeriesMapper(chartContext, chartType, collection, config) {
  const [chartSeries] = collection;
  const [series] = chartSeries;

  if (!series.length) return undefined;

  const [colorStepsArr] = colorSteps(chartSeries);

  return colorStepsArr.map((step, i) => {
    return [...series[i], step];
  });
}

function strikeZoneSeriesProvider(chartContext, data = [], filters = {}) {
  const binning = new StrikeZoneDataBinning();
  const provider = new Providers.StrikeZoneHeatMapSeriesProvider(binning);
  const binValueConverter = new Converters.BinValueConverters.BinToCountConverter();

  const chartSeries = provider.getSeriesForHeatMap(data, binValueConverter, filters);

  return chartSeries.series;
}

export {
  POV,
  SVG,
  ZONES,
  magnify,
  scalePoints,
  pointToSvgPoint,
  toZone,
  colorSteps,
  strikeZonePropsProvider,
  strikeZonePropsGuard,
  strikeZoneSeriesMapper,
  strikeZoneSeriesProvider,
};
